import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import CustomTag from "../../../custom/CustomTag";
import { CircularProgress } from "@mui/material";
import CustomList from "../../../custom/CustomList";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";

const GroupView = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        setIsLoaded(false);
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "books/groups/" + groupId, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    setGroup(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [groupId, token]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Nom",
                icon: "user",
                Cell: (row) => <Link to={"/admin/inventaire/clients/" + row.row.original.id}>{row.row.original.data.contact_name}</Link>,
            },
            {
                Header: "Statut",
                accessor: "data.status",
                isFiltered: true,
                icon: "role",
                Cell: ({ cell: { value } }) => <CustomTag value={value} />,
            },
            {
                Header: "Support",
                accessor: "isPremium",
                icon: "role",
                Cell: ({ cell: { value } }) => <CustomTag value={value ? "Premium" : "Essentiel"} className={value ? "premium" : "debit"} />,
            },
            {
                Header: "Heures",
                accessor: "supportHours",
                icon: "hourglass",
                Cell: ({ cell: { value } }) => <CustomTag value={value ? value + " h" : 0 + " h"} />,
            },
        ],
        []
    );

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce groupe seront perdues.")) {
            axios
                .delete(process.env.REACT_APP_UPA_API_HOST + "books/groups/" + groupId, { headers: { Authorization: "Bearer " + token } })
                .then(() => {
                    toast.success("Groupe supprimé", toastOptions);
                    navigate(-1);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    };

    const popover = (
        <div className="user-options" onClick={popoverVisible ? hide : show}>
            <div className="user-options-item delete" onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title comptabilite">
                    <h1>Groupe {group.name}</h1>
                </div>
                <div className="view-buttons">
                    <Link to="edit">
                        <button className="main-button blue-button edit">Modifier</button>
                    </Link>
                    <Tippy content={popover} allowHTML={true} placement={"bottom-end"} offset={[0, 5]} visible={popoverVisible} onClickOutside={hide} interactive={true} appendTo={"parent"}>
                        <button className="squared-button margin-left" onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label role">Support mutualisé</span>
                <CustomTag className={group.shared ? "true" : "false"} value={group.shared ? "Oui" : "Non"} />
            </div>
            <div className="input-orders-container">
                <span className="input-label user">Hôtels</span>
                <CustomList entity={group.contacts} columns={columns} isSelectable={false} isClickable={false} isPaginable={false} isFetchable={false} />
            </div>
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default GroupView;
