import {CheckCircle, WarningCircle} from "@phosphor-icons/react";
import React from "react";

const WeekTabLabel = ({ dayName, dateStr, hours }) => {
    const isComplete = hours >= 7;
    return (
        <div
            style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: 4,
            }}
        >
            <div>
                {dayName} {dateStr}
            </div>
            <div
                style={{
                    color: isComplete ? "var(--green-500)" : "var(--yellow-400)",
                    fontWeight: "bold",
                    fontSize: "0.85rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                }}
            >
                {hours} h {isComplete ? <CheckCircle size={16} weight="bold" color="var(--green-500)" /> : <WarningCircle size={16} weight="bold" color="var(--yellow-400)" />}
            </div>
        </div>
    );
};

export default WeekTabLabel;