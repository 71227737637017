import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../../custom/CustomTag";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { ArrowUpRight, DownloadSimple, Trash } from "@phosphor-icons/react";

const TestView = () => {
    const [test, setTest] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const { testId, userId } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId + "/" + userId, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    setTest(response.data.test);
                    setUser(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error("Candidat inconnu", toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate, token, testId, userId]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce candidat seront perdues.")) {
            axios
                .delete(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId + "/" + user.id, { headers: { Authorization: "Bearer " + token } })
                .then(() => {
                    toast.success("Candidat supprimé", toastOptions);
                    navigate(-1);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    };

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="view-title">
                    <h1>
                        {user.firstName} {user.lastName.toUpperCase()}
                    </h1>
                </div>
                <div className="view-buttons">
                    <a href={process.env.REACT_APP_UPA_INTERFACE_HOST + "tests/" + test.id + "/" + user.id} target="_blank" rel="noreferrer" className="main-button blue-button">
                        <ArrowUpRight size={16} weight="bold" />
                        Page du candidat
                    </a>
                    <button className="main-button warning-button delete" onClick={handleDelete}>
                        <Trash size={16} weight="bold" />
                        Supprimer
                    </button>
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label statut">Nom du test :</span>
                <CustomTag value={test.name} />
            </div>
            <div className="input-row-container">
                <span className="input-label email-black">Email :</span>
                <CustomTag value={user.email} />
            </div>
            <div className="input-row-container">
                <span className="input-label calendar">Inscription au test :</span>
                <CustomTag value={dayjs(user.createdAt).format("DD MMMM YYYY à HH:mm")} />
            </div>
            <div className="input-row-container">
                <span className="input-label time">Passage du test :</span>
                <CustomTag value={dayjs(user.startDate).format("DD MMMM")} />
                <span className="margin-right margin-left">de</span>
                <CustomTag value={dayjs(user.startDate).format("HH:mm")} />
                <span className="margin-right margin-left">à</span>
                <CustomTag value={dayjs(user.stopDate).format("HH:mm")} />
            </div>
            <div className="input-row-container">
                <span className="input-label file-black">Dépôt de fichier :</span>
                {user.submittedAt ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                        >
                            <CustomTag value={"Déposé à " + dayjs(user.submittedAt).format("HH:mm")} className="success" />
                            <a href={process.env.REACT_APP_UPA_API_HOST + "tests-uploads/" + user.id + ".zip"} className="download-test">
                                <DownloadSimple size={16} weight="bold" />
                            </a>
                        </div>
                    </>
                ) : (
                    <CustomTag className="failed" value={"Aucun fichier déposé"} />
                )}
            </div>
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={20} className="white-loader" />
        </div>
    );
};

export default TestView;
