import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UserAdd from "./UserAdd";
import User from "./UserView";
import UserList from "./UserList";
import PermissionsGate from "../PermissionsGate";
import { EngineProvider } from "../../../contexts/EngineContext";

const Users = () => {
    const moduleName = "users";
    return (
        <EngineProvider>
            <Routes>
                <Route path="/" element={
                    <PermissionsGate module={moduleName} submodule="users-list">
                        <UserList />
                    </PermissionsGate>
                } />
                <Route path="new" element={
                    <PermissionsGate module={moduleName} submodule="users-list">
                        <UserAdd />
                    </PermissionsGate>
                } />
                <Route path=":userId" module={moduleName} submodule="profile" element={<User />} />
                <Route path="*" element={<Navigate to="/:userId" />} />
            </Routes>
        </EngineProvider>
    );
};

export default Users;
