import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import constants from "../../../assets/constants/constants";
import { Checkbox, CircularProgress, Grid } from "@mui/material";

const RoleEdit = () => {
    const { roleId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [role, setRole] = useState();

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "users/roles/" + roleId, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    setRole(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error("Utilisateur inconnu", toastOptions);
                    navigate("/admin/settings");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, roleId, navigate]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios
            .put(process.env.REACT_APP_UPA_API_HOST + "users/roles/" + roleId, role, { headers: { Authorization: "Bearer " + token } })
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le rôle a bien été modifié", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    };

    const handleSelectModule = (event, module) => {
        let selectedModules = [...role.modules];
        if (event.target.checked) {
            selectedModules.push({ value: module.value, submodules: [] });
        } else {
            selectedModules = selectedModules.filter((selectedModule) => selectedModule.value !== module.value);
        }
        setRole({ ...role, modules: selectedModules });
    };

    const handleSelectSubmodule = (event, module, submodule) => {
        let selectedModules = [...role.modules];
        if (event.target.checked) {
            selectedModules.find((mod) => mod.value === module.value).submodules.push(submodule.value);
        } else {
            const foundSubmodules = selectedModules.find((mod) => mod.value === module.value).submodules;
            foundSubmodules.splice(foundSubmodules.indexOf(submodule.value));
        }
        setRole({ ...role, modules: selectedModules });
    };

    React.useEffect(() => console.log(role), [role]);

    return isLoaded ? (
        <div>
            <div className="list-header">
                <div className="view-title">
                    <h1>Éditer un rôle</h1>
                </div>
                <div className="view-buttons">
                    <button onClick={() => navigate(-1)} className="main-button without-icon warning-button">
                        Annuler
                    </button>
                    <button className="main-button blue-button edit" onClick={handleUpdate} disabled={buttonLoading}>
                        {buttonLoading ? <CircularProgress size={20} className="white-loader" /> : "Modifier"}
                    </button>
                </div>
            </div>
            <div className="input-group without-top">
                <div className="input-row-container">
                    <span className="input-label role">
                        Nom <span className="required">*</span>
                    </span>
                    <div className="input-container without-icon">
                        <input
                            value={role.name}
                            className="classic-input"
                            placeholder="Entrez un nom"
                            onChange={(e) => {
                                setRole((role) => {
                                    return { ...role, name: e.target.value };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="grid-container">
                    <Grid container spacing={2}>
                        {constants.modules.map((module) => (
                            <Grid item md={4}>
                                <div className="roles-module">
                                    <div className="roles-module-title">
                                        <Checkbox onChange={(e) => handleSelectModule(e, module)} checked={role.modules.some((mod) => mod.value === module.value)} />
                                        <span>{module.label}</span>
                                    </div>
                                    <div className="roles-submodule">
                                        {module.submodules.map((submodule) => (
                                            <div className="roles-submodule-input">
                                                <Checkbox
                                                    label="Label"
                                                    checked={role.modules.some((mod) => mod.value === module.value && mod.submodules.some((sub) => sub === submodule.value))}
                                                    disabled={!role.modules.some((selectedModule) => selectedModule.value === module.value)}
                                                    onChange={(e) => handleSelectSubmodule(e, module, submodule)}
                                                />
                                                <span>{submodule.label}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default RoleEdit;
