import React, { useContext, useState, useEffect, useCallback } from "react";
import { Tabs, Tab, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import GeneralView from "./GeneralView";
import Notifications from "./Notifications";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";

const UserView = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token, user: loggedUser } = useContext(AuthContext);
    const [tab, setTab] = useState(0);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const [userResponse, rolesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_UPA_API_HOST}users/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${process.env.REACT_APP_UPA_API_HOST}users/roles`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);
                setUser(userResponse.data);
                setRoles(rolesResponse.data);
                setIsLoaded(true);
            } catch (error) {
                toast.error("Erreur lors du chargement de l'utilisateur", toastOptions);
                navigate("/admin/settings");
            }
        };
        fetchUserData();
    }, [userId, token, navigate]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleUpdateUser = useCallback(
        async (updatedUser) => {
            try {
                await axios.put(
                    `${process.env.REACT_APP_UPA_API_HOST}users/${userId}`,
                    { user: updatedUser },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setUser(updatedUser);
                toast.success("Utilisateur mis à jour avec succès", toastOptions);
            } catch (error) {
                toast.error("Erreur lors de la mise à jour", toastOptions);
            }
        },
        [token, userId]
    );

    const handleArchive = useCallback(() => {
        const newStatus = user.status === "archived" ? "active" : "archived";
        axios
            .put(`${process.env.REACT_APP_UPA_API_HOST}users/${userId}`, { user: { ...user, status: newStatus } }, { headers: { Authorization: `Bearer ${token}` } })
            .then(() => {
                toast.success(`Utilisateur ${newStatus === "active" ? "rétabli" : "archivé"}`, toastOptions);
                setUser({ ...user, status: newStatus });
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastOptions);
            });
    }, [user, userId, token]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de cet utilisateur seront perdues.")) {
            axios
                .delete(`${process.env.REACT_APP_UPA_API_HOST}users/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(() => {
                    toast.success("Utilisateur supprimé", toastOptions);
                    navigate(-1);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    };

    const popover = (
        <div className="user-options" onClick={() => setPopoverVisible(false)}>
            <div className="user-options-item archive" onClick={handleArchive}>
                <span>{user && user.status === "archived" ? "Rétablir" : "Archiver"}</span>
            </div>
            <div className="user-options-item delete" onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="view-title">
                    {user && user.backgroundColor && (
                        <div className="member-icon large" style={{ backgroundColor: user.backgroundColor }}>
                            {user.firstName.charAt(0)}
                            {user.lastName.charAt(0)}
                        </div>
                    )}
                    <h1>{user ? `${user.firstName} ${user.lastName}` : "Chargement..."}</h1>
                </div>
                <div className="view-buttons">
                    {loggedUser && user && user.id !== loggedUser.id && (
                        <Tippy content={popover} interactive={true} placement="bottom-end" visible={popoverVisible} onClickOutside={() => setPopoverVisible(false)}>
                            <button className="squared-button margin-left" onClick={() => setPopoverVisible(!popoverVisible)}>
                                Options
                            </button>
                        </Tippy>
                    )}
                </div>
            </div>

            {isLoaded ? (
                <>
                    <div className="tabs-container">
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label="Dashboard" />
                            <Tab label="Notifications" />
                        </Tabs>

                        {tab === 0 && (
                            <GeneralView user={user} roles={roles} onUpdateUser={handleUpdateUser} onColorChange={(newColor) => setUser((prevUser) => ({ ...prevUser, backgroundColor: newColor }))} />
                        )}
                        {tab === 1 && <Notifications user={user} />}
                    </div>
                </>
            ) : (
                <div className="loader-container absolute">
                    <CircularProgress size={30} />
                </div>
            )}
        </div>
    );
};

export default UserView;
