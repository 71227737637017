import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CustomList from "../../../custom/CustomList";
import AuthContext from "../../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import CustomTag from "../../../custom/CustomTag";

const ContactMacro = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [totalMois, setTotalMois] = useState(0);
    const [totalTrimestre, setTotalTrimestre] = useState(0);
    const [totalAnnee, setTotalAnnee] = useState(0);
    const mrrTotal = totalMois + totalTrimestre / 3 + totalAnnee / 12;
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}pennylane/get-subscriptions`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                const rawData = response.data;
                let sommeMois = 0;
                let sommeTrimestre = 0;
                let sommeAnnee = 0;

                const dataSubscription = rawData.map((subscription) => {
                    let totalMois = 0;
                    let totalTrimestre = 0;
                    let totalAnnee = 0;

                    subscription.invoice_template?.line_items.forEach((line) => {
                        const montantFacturation = parseFloat(line.currency_amount || 0);
                        if (subscription.recurring_rule?.rule_type === "monthly") {
                            if (subscription.recurring_rule?.interval === 1) {
                                totalMois += montantFacturation;
                                sommeMois += montantFacturation;
                            } else if (subscription.recurring_rule?.interval === 3) {
                                totalTrimestre += montantFacturation;
                                sommeTrimestre += montantFacturation;
                            }
                        } else if (subscription.recurring_rule?.rule_type === "yearly") {
                            totalAnnee += montantFacturation;
                            sommeAnnee += montantFacturation;
                        }
                    });

                    return {
                        contact_name: subscription.customer?.name || "Non renseigné",
                        mois: totalMois,
                        trimestre: totalTrimestre,
                        annee: totalAnnee,
                        idAltelis: subscription.customer?.reference || "Non renseigné",
                    };
                });

                const sortedData = dataSubscription.sort((a, b) => {
                    const idA = parseInt(a.idAltelis, 10) || 0;
                    const idB = parseInt(b.idAltelis, 10) || 0;
                    return idB - idA;
                });

                setSubscriptions(sortedData);
                setTotalMois(sommeMois);
                setTotalTrimestre(sommeTrimestre);
                setTotalAnnee(sommeAnnee);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des abonnements :", error);
                setIsLoaded(true);
            });
    }, [token]);

    const affichageCustomTag = (value) => {
        const isNumber = typeof value === "number" && !isNaN(value);
        return <CustomTag value={isNumber ? `${value.toFixed(2)}€` : "Non renseigné"} className={isNumber && value > 0 ? "active" : "deactivated"} />;
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Id Altelis",
                accessor: "idAltelis",
                icon: "id",
                Cell: ({ value }) => <CustomTag value={value} className={value !== "Non renseigné" ? "" : "deactivated"} />,
            },
            {
                Header: "Nom de l'hôtel",
                accessor: "contact_name",
                icon: "user",
                Cell: ({ value }) => value.toUpperCase(),
            },
            {
                Header: "Tous les mois",
                accessor: "mois",
                icon: "calendar",
                Cell: ({ value }) => affichageCustomTag(value),
            },
            {
                Header: "Tous les 3 mois",
                accessor: "trimestre",
                icon: "calendar",
                Cell: ({ value }) => affichageCustomTag(value),
            },
            {
                Header: "Tous les ans",
                accessor: "annee",
                icon: "calendar",
                Cell: ({ value }) => affichageCustomTag(value),
            },
            {
                Header: "MRR",
                accessor: (row) => row.mois + row.trimestre / 3 + row.annee / 12,
                id: "mrr",
                icon: "euro",
                Cell: ({ value }) => affichageCustomTag(value),
            },
        ],
        []
    );

    return (
        <div className="macro-wrapper">
            {isLoaded ? (
                <>
                    <div className="macro-container">
                        <div className="macro-content">
                            <CustomTag
                                value={
                                    <>
                                        <span>
                                            <strong>Mois : </strong>
                                            {totalMois.toFixed(2)}€
                                        </span>
                                    </>
                                }
                                className="blue"
                            />
                            <CustomTag
                                value={
                                    <>
                                        <span>
                                            <strong>Trimestre : </strong>
                                            {totalTrimestre.toFixed(2)}€
                                        </span>
                                    </>
                                }
                                className="blue"
                            />
                            <CustomTag
                                value={
                                    <>
                                        <span>
                                            <strong>Année : </strong>
                                            {totalAnnee.toFixed(2)}€
                                        </span>
                                    </>
                                }
                                className="blue"
                            />
                        </div>
                        <span>
                            <CustomTag
                                value={
                                    <>
                                        <span>
                                            <strong>MRR : </strong>
                                            {mrrTotal.toFixed(2)}€
                                        </span>
                                    </>
                                }
                                className="blue"
                            />
                        </span>
                    </div>
                    <div className="tabs-container">
                        <CustomList columns={columns} entity={subscriptions} isSelectable={false} isFetchable={false} isClickable={false} isDownloadable={false} isFixed={false} />
                    </div>
                </>
            ) : (
                <div className="loader-container absolute">
                    <CircularProgress size={30} />
                </div>
            )}
        </div>
    );
};

export default ContactMacro;
