import { CaretRight } from "@phosphor-icons/react";

const TaskOption = ({ innerProps, tasks, sections, ...props }) => {
    const task = props.data;
    const isSubtask = task.parentId !== null;
    const parent = isSubtask ? tasks.find((t) => t.id === task.parentId) : null;
    const section = sections.find((s) => s.id === task.sectionId);

    return (
        <div {...innerProps} className="task-option-container">
            <div className="task-option-data">
                <div className="task-option-section">
                    <span className="section">{section.name}</span>
                    {isSubtask && (
                        <>
                            <CaretRight size={8} weight="bold" color="#a1a1a1" />
                            <span className="subtask-parent">{parent.name}</span>
                        </>
                    )}
                </div>
                <span className="main-task-option">{task.name}</span>
            </div>
        </div>
    );
};

export default TaskOption;
